import { TableContainer, Table, Tbody, Th, Thead, Tr, useToast } from "@chakra-ui/react";
import EventTableLostItem from "./EventTableLostItem";

const AdminEventLost = (client, eventType, pagiEvents) => {
	return (
		<TableContainer>
			<Table variant='simple' h='20vh'>
				<Thead>
					<Tr>
						<Th style={{ fontSize: "1vw", fontFamily: "Arial" }}>STORE</Th>
						<Th style={{ fontSize: "1vw", fontFamily: "Arial" }}>FNSKU</Th>
						<Th style={{ fontSize: "1vw", fontFamily: "Arial" }}>STATUS</Th>
						<Th style={{ fontSize: "1vw", fontFamily: "Arial" }}>CASE ID</Th>
						<Th style={{ fontSize: "1vw", fontFamily: "Arial" }}>Quantity</Th>
						<Th style={{ fontSize: "1vw", fontFamily: "Arial" }}>Value</Th>
						<Th style={{ fontSize: "1vw", fontFamily: "Arial" }}>Reimbursement ID</Th>
					</Tr>
				</Thead>
				<Tbody>
					{pagiEvents.length > 0 &&
						pagiEvents.map((singleEvent) => (
							<EventTableLostItem
								key={singleEvent._id}
								singleEvent={singleEvent}
								client={client}
								eventType={eventType}
							/>
						))}
				</Tbody>
			</Table>
		</TableContainer>
	);
};

export default AdminEventLost;
