import { Td, Tr, Input, VStack, Button, Text, useToast, Select } from "@chakra-ui/react";
import { useDispatch, useSelector } from "react-redux";
import { createCase } from "../redux/actions/adminActions.js";
import {
	setEventError,
	setEventIsSaved,
	setEventIsUnsaved,
	setEventStatus,
	setCaseID,
	setQuantity,
	setValue,
	setReimbID,
} from "../redux/slices/event.js";

const EventTableLostItem = ({ singleEvent, client, eventType }) => {
	const dispatch = useDispatch();
	const { addedCaseID } = useSelector((state) => state.adminCase);
	const { isSaved, eventStatus, caseID, quantityReimb, valueReimb, reimbID } = useSelector(
		(state) => state.event.events[singleEvent.idx]
	);

	const saveButtonClicked = () => {
		// error check
		if (eventStatus === "") {
			dispatch(setEventError("Status is not choosen."));
			return;
		} else if (eventStatus === "Reimbursed (Value TBD)") {
			if (caseID === "") {
				dispatch(setEventError("CASE ID is empty."));
				return;
			} else if (isNaN(Number(caseID))) {
				dispatch(setEventError("CASE ID is not a number."));
				return;
			} else if (!(caseID.length === 11)) {
				dispatch(setEventError("CASE ID should contain 11 digits."));
				return;
			} else if (caseID in addedCaseID && !(addedCaseID[caseID] === singleEvent.fnsku)) {
				dispatch(setEventError("Duplicated CASE ID. Please double check the CASE ID."));
				return;
			}
			if (quantityReimb === "") {
				dispatch(setEventError("QUANTITY is empty."));
				return;
			} else if (isNaN(Number(quantityReimb))) {
				dispatch(setEventError("QUANTITY is not a number."));
				return;
			}
			if (!(valueReimb === "")) {
				dispatch(setEventError("Reimbursed (Value TBD) event should not have reimbursed value."));
				return;
			}
			if (reimbID === "") {
				dispatch(setEventError("REIMBURSEMENT ID is empty."));
				return;
			} else if (isNaN(Number(reimbID))) {
				dispatch(setEventError("REIMBURSEMENT ID is not a number."));
				return;
			} else if (!(reimbID.length === 11)) {
				dispatch(setEventError("REIMBURSEMENT ID should contain 11 digits."));
				return;
			} else if (reimbID === caseID) {
				dispatch(setEventError("REIMBURSEMENT ID is the same with the CASE ID"));
				return;
			}
		} else if (eventStatus === "Negotiating") {
			if (caseID === "") {
				dispatch(setEventError("CASE ID is empty."));
				return;
			} else if (isNaN(Number(caseID))) {
				dispatch(setEventError("CASE ID is not a number."));
				return;
			} else if (!(caseID.length === 11)) {
				dispatch(setEventError("CASE ID should contain 11 digits."));
				return;
			} else if (caseID in addedCaseID && !(addedCaseID[caseID] === singleEvent.fnsku)) {
				dispatch(setEventError("Duplicated CASE ID. Please double check the CASE ID."));
				return;
			}
			if (!(valueReimb === "")) {
				dispatch(setEventError("Negotiating event should not have reimbursed value."));
				return;
			}
			if (!(reimbID === "")) {
				dispatch(setEventError("Negotiating event should not have reimbursement ID."));
				return;
			}
		}
		dispatch(setEventIsSaved(singleEvent.idx));
		dispatch(setEventError(null));
		dispatch(
			createCase(
				singleEvent.fnsku,
				singleEvent.storeName,
				eventType,
				eventStatus,
				caseID,
				quantityReimb,
				valueReimb,
				reimbID
			)
		);
	};

	return (
		<Tr fontSize='1vw'>
			<Td>
				<Text>{singleEvent.storeName}</Text>
			</Td>
			<Td>
				<Text>{singleEvent.fnsku}</Text>
			</Td>
			{/* status */}
			<Td>
				<Select
					w={{ base: "15em", md: "15em", lg: "15em" }}
					placeholder='Select Status'
					value={eventStatus}
					id='eventStatus'
					style={{ fontSize: "1vw", fontFamily: "Arial", fontWeight: "bold" }}
					onChange={(e) => {
						dispatch(setEventStatus({ idx: singleEvent.idx, eventStatus: e.target.value }));
						dispatch(setEventIsUnsaved(singleEvent.idx));
					}}
				>
					<option value='Reimbursed (Value TBD)'>Reimbursed (Value TBD)</option>
					<option value='Negotiating'>Negotiating</option>
					<option value='NA'>Not Applicable</option>
				</Select>
			</Td>
			{/* case ID */}
			<Td>
				<Input
					id='caseId'
					w={{ base: "9em", md: "9em", lg: "9em" }}
					value={caseID}
					onChange={(e) => {
						dispatch(setCaseID({ idx: singleEvent.idx, caseID: e.target.value }));
						dispatch(setEventIsUnsaved(singleEvent.idx));
					}}
				/>
			</Td>
			{/* quantity reimbursed */}
			<Td>
				<Input
					id='quantityReimb'
					w={{ base: "6em", md: "6em", lg: "6em" }}
					value={quantityReimb}
					onChange={(e) => {
						dispatch(setQuantity({ idx: singleEvent.idx, quantityReimb: e.target.value }));
						dispatch(setEventIsUnsaved(singleEvent.idx));
					}}
				/>
			</Td>
			{/* value reimbursed */}
			<Td>
				<Input
					id='valueReimb'
					w={{ base: "6em", md: "6em", lg: "6em" }}
					value={valueReimb}
					onChange={(e) => {
						dispatch(setValue({ idx: singleEvent.idx, valueReimb: e.target.value }));
						dispatch(setEventIsUnsaved(singleEvent.idx));
					}}
				/>
			</Td>
			{/* Reimbursement ID */}
			<Td>
				<Input
					id='reimbId'
					w={{ base: "9em", md: "9em", lg: "9em" }}
					value={reimbID}
					onChange={(e) => {
						dispatch(setReimbID({ idx: singleEvent.idx, reimbID: e.target.value }));
						dispatch(setEventIsUnsaved(singleEvent.idx));
					}}
				/>
			</Td>
			<Td>
				<VStack>
					<Button
						id='saveButton'
						colorScheme='green'
						size='xs'
						variant='outline'
						onClick={saveButtonClicked}
						isDisabled={isSaved ? true : false}
					>
						{isSaved ? "Saved" : "Save Changes"}
					</Button>
				</VStack>
			</Td>
		</Tr>
	);
};

export default EventTableLostItem;
