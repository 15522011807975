import "../Styles/globals.css";
import { Flex, useToast } from "@chakra-ui/react";
import { useSelector, useDispatch } from "react-redux";
import { useEffect } from "react";
import { Link as ReactLink, useLocation, useNavigate, useSearchParams } from "react-router-dom";
import ClientBilling from "../components/ClientBilling.jsx";
import ClientHeader from "../components/ClientHeader";
import { setCurNavTab } from "../redux/slices/user";

const PortalBillingScreen = (req, res) => {
	const dispatch = useDispatch();
	const navigate = useNavigate();
	const toast = useToast();
	const { userInfo } = useSelector((state) => state.user);
	// const [searchParams, setSearchParams] = useSearchParams();

	useEffect(() => {
		if (userInfo === null) {
			navigate("/portal");
		} else {
			dispatch(setCurNavTab("nav-billing"));
		}
	}, [dispatch, navigate, userInfo]);

	return (
		// The viewport flexbox.'
		<Flex h='100vh' w='100vw' overflow='hidden' bgColor='rgba(242, 242, 242, 0.9)'>
			{/* Column 1 (Nav Bar) */}
			<ClientHeader />
			{/* Column 2 */}
			<ClientBilling />
		</Flex>
	);
};

export default PortalBillingScreen;
