import { combineReducers, configureStore } from '@reduxjs/toolkit';
import user from './slices/user.js';
import userCases from './slices/userCase.js';
import event from './slices/event.js';
import adminCase from './slices/adminCase.js';
import adminClientCase from './slices/adminClientCase.js';

const reducer = combineReducers({
    user,
    userCases,
    event,
    adminCase,
    adminClientCase
});

export default configureStore({ reducer });

