import { useEffect } from "react";
import { Elements } from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";
import SetupForm from "./SetupForm";
import { useSelector, useDispatch } from "react-redux";
import { Flex, Heading, Box, Text, Icon, Divider, Center, Spinner } from "@chakra-ui/react";
import PaymentCard from "./PaymentCard";
import { getFinancialInfo } from "../redux/actions/checkoutActions";
import EmptyNotice from "./EmptyNotice";
import { setFinantialInfoLoading } from "../redux/slices/user";
import { useSearchParams } from "react-router-dom";
import SavingCardResult from "./SavingCardResult";
import Footer from "./Footer";

// import dotenv from 'dotenv';
// dotenv.config();

// Make sure to call `loadStripe` outside of a component’s render to avoid
// recreating the `Stripe` object on every render.
const stripePromise = loadStripe(
	"pk_live_51PxEc7FDXKwE0gwcmJV3QzIhFJ3HMU1ZQOawYJP5Tw5mBzNwURxP9kZLIUtU6qIoKHRW5djYmQhoFA8xS8cjbbar00qYkng77L"
);

const ClientPayment = () => {
	const [searchParams, setSearchParams] = useSearchParams();
	const {
		userInfo,
		stripeIntentSecret,
		stripeCustomerSessionClientSecret,
		stripeCreditCardInfo,
		finantialInfoLoading,
	} = useSelector((state) => state.user);
	const options = {
		// passing the SetupIntent's client secret
		clientSecret: stripeIntentSecret,
		customerSessionclientSecret: stripeCustomerSessionClientSecret,
		// Fully customizable with appearance API.
		appearance: {
			theme: "stripe",
			variables: {
				colorPrimary: "#bfbfbf",
				colorBackground: "#ffffff",
				colorText: "#30313d",
				colorDanger: "#df1b41",
				fontFamily: "Ideal Sans, system-ui, sans-serif",
				spacingUnit: "2px",
				borderRadius: "4px",
				fontSizeBase: "10px",

				// See all possible variables below
			},
			layout: {
				type: "accordion",
				defaultCollapsed: false,
				radios: true,
				spacedAccordionItems: false,
			},
		},
	};
	const dispatch = useDispatch();

	useEffect(() => {
		dispatch(setFinantialInfoLoading(true));
		dispatch(getFinancialInfo(userInfo.name, userInfo.email, userInfo.stripeCustomerId));
	}, [dispatch, userInfo.name, userInfo.email, userInfo.stripeCustomerId]);

	return (
		<Flex w='85vw' overflow='auto'>
			{/* Row 1 */}
			<Flex
				w='100%'
				h={{ base: "150vh", md: "100vh" }}
				alignItems='center'
				justifyContent='center'
				flexDir={{ base: "column", md: "row" }}
				overflow='auto'
			>
				<Flex
					w={{ base: "100%", md: "50%" }}
					h={{ base: "70vh", md: "100%" }}
					alignItems='center'
					justifyContent='center'
				>
					{finantialInfoLoading ? (
						<Spinner />
					) : stripeCreditCardInfo ? (
						<PaymentCard />
					) : searchParams.get("setup_intent_client_secret") ? (
						<SavingCardResult />
					) : (
						<EmptyNotice />
					)}
				</Flex>

				<Flex
					w={{ base: "100%", md: "50%" }}
					h={{ base: "110vh", md: "100%" }}
					bgColor='#4FD1C5'
					justifyContent='center'
					alignItems='center'
				>
					{finantialInfoLoading ? (
						<Spinner />
					) : (
						<Flex
							className='component-dashboard'
							h={{ base: "60%", sm: "70%", md: "80%", lg: "80%" }}
							aspectRatio={{ base: "0.5", sm: "0.5", md: "0.6", lg: "0.58", xl: "0.7", "2xl": "0.9" }}
							borderRadius='1vw'
							borderWidth='0.1vmax'
							justifyContent='center'
							alignItems='center'
							bgColor='white'
							flexDir='column'
						>
							{/* <Flex w='100%' fontSize='0.5em' fontWeight='600' marginTop='1em' marginBottom='1em' paddingLeft='5%'>
								New Payment Method
							</Flex> */}
							<Elements stripe={stripePromise} options={options}>
								<SetupForm />
							</Elements>
						</Flex>
					)}
				</Flex>
			</Flex>
			{/* Row 2 */}
			{/* <Flex w='98%' h='10%' borderRadius='1vw' flexDir='column' alignItems='center' justifyContent='space-between'>
				<Divider border='0.01rem solid rgba(0, 0, 0, 0.1)' />
				<Footer />
			</Flex> */}
		</Flex>
	);
};

export default ClientPayment;
