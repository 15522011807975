import {
	Alert,
	AlertDescription,
	AlertIcon,
	AlertTitle,
	Divider,
	Spinner,
	Stack,
	Wrap,
	Flex,
	Button,
	Select,
	Icon,
	useToast,
} from "@chakra-ui/react";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import React from "react";
import { Link as ReactLink, useNavigate } from "react-router-dom";
import { getLostEvent, getPagiEvents } from "../redux/actions/eventActions";
import { MdKeyboardArrowLeft } from "react-icons/md";
import { MdKeyboardArrowRight } from "react-icons/md";
import AdminEventLost from "./AdminEventLost";
import { setEventError } from "../redux/slices/event.js";
import { saveToDatabaseAction } from "../redux/actions/adminActions.js";

const AdminEvent = () => {
	const navigate = useNavigate();
	const dispatch = useDispatch();
	const toast = useToast();

	const {
		loading,
		error,
		pagiEvents,
		pagination: { totalPages, currentPage },
	} = useSelector((state) => state.event);
	const { userInfo } = useSelector((state) => state.user);
	const { toSaveCases, toRemoveEvents } = useSelector((state) => state.adminCase);
	const [client, setClient] = useState("");
	const [eventType, setEventType] = useState("");

	useEffect(() => {
		if (!userInfo.isCaseAdmin) {
			navigate("/portal");
		}
		if (error) {
			toast({
				description: `${error}`,
				status: "error",
				isClosable: true,
			});
		}
	}, [navigate, userInfo, error, toast]);

	const searchEvents = () => {
		if (client === "") {
			setClient(null);
			setEventType(null);
			document.getElementById("selectClient").value = null;
			document.getElementById("selectType").value = null;
			dispatch(setEventError("Client is not choosen."));
			return;
		}
		if (eventType === "") {
			setClient(null);
			setEventType(null);
			document.getElementById("selectClient").value = null;
			document.getElementById("selectType").value = null;
			dispatch(setEventError("Event Type is not choosen."));
			return;
		}
		dispatch(getLostEvent(client, eventType));
	};

	const saveToDatabase = () => {
		dispatch(saveToDatabaseAction(Object.values(toSaveCases), Object.keys(toRemoveEvents), client, eventType));
	};

	const paginationButtonClick = (curPage) => {
		dispatch(getPagiEvents(curPage));
	};

	// Generate paginated buttons in accordance with users input.
	const genPagiBtns = () => {
		const allLeadBtns = () => {
			const buttons = [];
			for (let i = 1; i < currentPage; i++) {
				buttons.push(
					<Button key={i} onClick={() => paginationButtonClick(i)} size='sm'>
						{i}
					</Button>
				);
			}
			return buttons;
		};

		const allLastBtns = () => {
			const btns = [];
			for (let i = currentPage + 1; i <= totalPages; i++) {
				btns.push(
					<Button key={i} onClick={() => paginationButtonClick(i)} size='sm'>
						{i}
					</Button>
				);
			}
			return btns;
		};

		return (
			<>
				{currentPage - 3 > 1 ? (
					<>
						<Button key={1} onClick={() => paginationButtonClick(1)} size='sm'>
							1
						</Button>
						...
						<Button key={currentPage - 2} onClick={() => paginationButtonClick(currentPage - 2)} size='sm'>
							{currentPage - 2}
						</Button>
						<Button key={currentPage - 1} onClick={() => paginationButtonClick(currentPage - 1)} size='sm'>
							{currentPage - 1}
						</Button>
					</>
				) : (
					allLeadBtns()
				)}
				<Button colorScheme='teal' key={currentPage} onClick={() => paginationButtonClick(currentPage)} size='sm'>
					{currentPage}
				</Button>
				{currentPage + 3 < totalPages ? (
					<>
						<Button key={currentPage + 1} onClick={() => paginationButtonClick(currentPage + 1)} size='sm'>
							{currentPage + 1}
						</Button>
						<Button key={currentPage + 2} onClick={() => paginationButtonClick(currentPage + 2)} size='sm'>
							{currentPage + 2}
						</Button>
						...
						<Button key={totalPages} onClick={() => paginationButtonClick(totalPages)} size='sm'>
							{totalPages}
						</Button>
					</>
				) : (
					allLastBtns()
				)}
			</>
		);
	};

	return (
		// The overall wrapper
		<Flex
			className='component-dashboard'
			w={{ base: "100%", md: "100%" }}
			marginTop={{ base: "1em", md: "1em", lg: "1em" }}
			borderRadius='1em'
			bgColor='white'
			flexDir='column'
		>
			{error && (
				<Alert status='error'>
					<AlertIcon />
					<AlertTitle>Oops!</AlertTitle>
					<AlertDescription>{error}</AlertDescription>
				</Alert>
			)}
			{loading ? (
				<Wrap justify='center'>
					<Stack direction='row' spacing='4'>
						<Spinner mt='20' thickness='2px' speed='0.65s' emptyColor='gray.200' color='teal.500' size='xl' />
					</Stack>
				</Wrap>
			) : (
				<Flex
					alignItems='center'
					justifyContent='flex-start'
					w='100%'
					flexDir='column'
					borderRadius='1vw'
					overflow='auto'
				>
					{/* Row 1 */}
					<Flex
						w='95%'
						h='5em'
						fontSize='1.2vw'
						fontWeight='bold'
						alignItems='center'
						borderRadius='1vw'
						justifyContent='space-between'
						paddingLeft='0.5em'
						paddingRight='0.5em'
					>
						<Flex w={{ base: "25%", md: "25%", lg: "25%" }}>
							<Select
								id='selectClient'
								style={{ fontSize: "1vw", fontFamily: "Arial", fontWeight: "bold" }}
								placeholder='Select Client'
								onChange={(e) => setClient(e.target.value)}
							>
								{userInfo.clientEmails.length > 0 &&
									userInfo.clientEmails.map((clientEmail) => (
										<option key={clientEmail.clientEmail} value={clientEmail.clientEmail}>
											{clientEmail.clientEmail}
										</option>
									))}
							</Select>
						</Flex>
						<Flex w={{ base: "25%", md: "25%", lg: "25%" }}>
							<Select
								id='selectType'
								placeholder='Select Type'
								style={{ fontSize: "1vw", fontFamily: "Arial", fontWeight: "bold" }}
								onChange={(e) => {
									setEventType(e.target.value);
								}}
							>
								<option key='lost' value='lost'>
									Warehouse Lost
								</option>
								<option key='damaged' value='damaged'>
									Warehouse Damaged
								</option>
								<option key='customerReturn' value='customerReturn'>
									Customer Return
								</option>
								<option key='outbound' value='outbound'>
									Removal Shipping
								</option>
								<option key='inbound' value='inbound'>
									Inbound Shipping
								</option>
								<option key='other' value='other'>
									Others
								</option>
							</Select>
						</Flex>
						<Button size={{ base: "xs", md: "xs", lg: "xs" }} onClick={searchEvents}>
							Search
						</Button>
					</Flex>
					<Divider />
					{/* Row 2 */}
					<Flex alignItems='center' justifyContent='center' w='100%' overflow='auto'>
						{eventType === "lost" ? AdminEventLost(client, eventType, pagiEvents) : ""}
					</Flex>
					{/* Row 3 */}
					<Flex w='100%' alignItems='center' justifyContent='center' borderRadius='1vw'>
						<Wrap spacing='1vw' justify='center' p='1vw'>
							<Button
								id='casePagiLeftArrow'
								onClick={() => paginationButtonClick(currentPage - 1 > 0 ? currentPage - 1 : 1)}
								size='sm'
							>
								<Icon className='nav-items-icon' as={MdKeyboardArrowLeft} boxSize={3} />
							</Button>
							{genPagiBtns()}
							<Button
								id='casePagiRightArrow'
								onClick={() => paginationButtonClick(currentPage + 1 < totalPages ? currentPage + 1 : totalPages)}
								size='sm'
							>
								<Icon className='nav-items-icon' as={MdKeyboardArrowRight} boxSize={3} />
							</Button>
						</Wrap>
					</Flex>
					<Flex
						alignItems='center'
						justifyContent='center'
						w='90%'
						overflow='auto'
						marginTop={{ base: "6em", md: "6em" }}
						marginBottom={{ base: "2em", md: "2em" }}
					>
						<Button
							size={{ base: "sm", md: "sm", lg: "sm" }}
							onClick={saveToDatabase}
							variant='outline'
							colorScheme='green'
							isDisabled={Object.keys(toRemoveEvents).length === 0 ? true : false}
						>
							Save to Client's Database
						</Button>
					</Flex>
				</Flex>
			)}
		</Flex>
	);
};

export default AdminEvent;
