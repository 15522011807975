import { ChakraProvider, extendTheme } from '@chakra-ui/react'
import { Route, BrowserRouter as Router, Routes, useSearchParams } from 'react-router-dom';
import LandingScreen from './screens/LandingScreen';
import EmailVerificationScreen from './screens/EmailVerificationScreen';
import PasswordResetScreen from './screens/PasswordResetScreen';
import PortalScreen from './screens/PortalScreen';
import PortalDashboardScreen from './screens/PortalDashboardScreen';
import PortalInboundScreen from './screens/PortalInboundScreen';
import PortalPaymentScreen from './screens/PortalPaymentScreen';
import PortalStoreScreen from './screens/PortalStoreScreen';
import PortalBillingScreen from './screens/PortalBillingScreen';
import PortalCreditScreen from './screens/PortalCreditScreen';
import HelpScreen from './screens/HelpScreen';
import axios from 'axios';
import { VStack, Spinner } from '@chakra-ui/react';
import { useState, useEffect } from 'react';
import { GoogleOAuthProvider } from '@react-oauth/google';
import CaseAdminScreen from './screens/CaseAdminScreen';



function App() {
  const theme = extendTheme({
    fonts: {
      body: "Arial, system-ui, sans-serif",
      heading: "Arial, Georgia, serif",
      mono: "Arial, Menlo, monospace",
    }
  });

  const [googleClient, setGoogleClient] = useState(null);
  useEffect(() => {
    const googleKey = async () => {
      const { data: googleId } = await axios.get('/api/config/google');
      setGoogleClient(googleId);
    };
    googleKey();
  }, [googleClient]);



  return (
    <ChakraProvider theme={theme}>
      {!googleClient ? (
        <VStack pt='37vh'>
          <Spinner mt='20' thickness='2px' speed='0.65s' emptyColor='gray.200' color='cyan.500' size='xl' />
        </VStack>
      ) : (
        <GoogleOAuthProvider clientId={googleClient}>
          <Router>
            <main>
              <Routes>
                <Route path='/' element={<LandingScreen />} />
                <Route path='/help' element={<HelpScreen />} />
                <Route path='/portal' element={<PortalScreen />} />
                <Route path='/portal/dashboard' element={<PortalDashboardScreen />} />
                <Route path='/portal/inbound' element={<PortalInboundScreen />} />
                <Route path='/portal/store' element={<PortalStoreScreen />} />
                <Route path='/portal/payment' element={<PortalPaymentScreen />} />
                <Route path='/portal/credit' element={<PortalCreditScreen />} />
                <Route path='/portal/billing' element={<PortalBillingScreen />} />
                <Route path='/email-verify/:token' element={<EmailVerificationScreen />} />
                <Route path='/password-reset/:token' element={<PasswordResetScreen />} />
                <Route path='/admin/case-admin' element={<CaseAdminScreen />} />
              </Routes>
            </main>
          </Router>
        </GoogleOAuthProvider>
      )}
    </ChakraProvider>
  );
}

export default App;
