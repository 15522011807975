import {
	Td,
	Tr,
	Input,
	VStack,
	Button,
	FormControl,
	Select,
	FormLabel,
	InputGroup,
	InputRightElement,
} from "@chakra-ui/react";
import { MdOutlineDataSaverOn } from "react-icons/md";
import { DeleteIcon } from "@chakra-ui/icons";
import { useRef, useState } from "react";
import { updateStore } from "../redux/actions/userActions";
import { useDispatch, useSelector } from "react-redux";

const StoreTableItem = ({ singleStore }) => {
	const dispatch = useDispatch();
	const [showClientID, setShowClientID] = useState(false);
	const [showClientSecret, setShowClientSecret] = useState(false);
	const [showToken, setShowToken] = useState(false);

	const handleClickClientId = () => setShowClientID(!showClientID);
	const handleClickClientSecret = () => setShowClientSecret(!showClientSecret);
	const handleClickToken = () => setShowToken(!showToken);

	const [storeName, setStoreName] = useState(singleStore.storeName);
	const [marketplace, setMarketplace] = useState(singleStore.marketplace);
	const [clientId, setClientId] = useState(singleStore.clientId);
	const [clientSecret, setClientSecret] = useState(singleStore.clientSecret);
	const [refreshToken, setRefreshToken] = useState(singleStore.refreshToken);

	const userId = useSelector((state) => state.user.userInfo._id);
	const storeId = singleStore._id;

	const onSaveStore = () => {
		dispatch(updateStore(userId, storeName, storeId, marketplace, clientId, clientSecret, refreshToken));
	};

	return (
		<Tr fontSize='1vw'>
			<Td>
				<Input size='sm' value={storeName} onChange={(e) => setStoreName(e.target.value)} />
			</Td>
			<Td>
				<FormControl>
					<Select
						placeholder='Select Marketplace'
						id='selectMarket'
						value={marketplace}
						size='sm'
						onChange={(e) => setMarketplace(e.target.value)}
					>
						<optgroup label='Americas'>
							<option value='United States'>United States</option>
							<option value='Canada'>Canada</option>
							<option value='Mexico'>Mexico</option>
							<option value='Brazil'>Brazil</option>
						</optgroup>

						<optgroup label='Europe'>
							<option value='Germany'>Germany</option>
							<option value='Spain'>Spain</option>
							<option value='France'>France</option>
							<option value='Italy'>Italy</option>
							<option value='Netherlands'>Netherlands</option>
							<option value='Poland'>Poland</option>
							<option value='Sweden'>Sweden</option>
							<option value='United Kingdom'>United Kingdom</option>
							<option value='Turkey'>Turkey</option>
						</optgroup>

						<optgroup label='Asia-Pacific'>
							<option value='Australia'>Australia</option>
							<option value='Japan'>Japan</option>
							<option value='India'>India</option>
							<option value='Singapore'>Singapore</option>
						</optgroup>

						<optgroup label='Middle East'>
							<option value='United Arab Emirates'>United Arab Emirates</option>
							<option value='Egypt'>Egypt</option>
							<option value='Saudi Arabia'>Saudi Arabia</option>
						</optgroup>
					</Select>
				</FormControl>
			</Td>
			<Td>
				<InputGroup size='sm'>
					<Input
						pr='4.5rem'
						type={showClientID ? "text" : "password"}
						value={clientId}
						onChange={(e) => setClientId(e.target.value)}
					/>
					<InputRightElement width='3.5rem'>
						<Button h='1.75rem' size='xs' onClick={handleClickClientId}>
							{showClientID ? "Hide" : "Show"}
						</Button>
					</InputRightElement>
				</InputGroup>
			</Td>
			<Td>
				{/* <Input size='sm' value={singleStore.clientSecret} /> */}
				<InputGroup size='sm'>
					<Input
						pr='4.5rem'
						type={showClientSecret ? "text" : "password"}
						value={clientSecret}
						onChange={(e) => setClientSecret(e.target.value)}
					/>
					<InputRightElement width='3.5rem'>
						<Button h='1.75rem' size='xs' onClick={handleClickClientSecret}>
							{showClientSecret ? "Hide" : "Show"}
						</Button>
					</InputRightElement>
				</InputGroup>
			</Td>
			<Td>
				{/* <Input size='sm' value={singleStore.refreshToken} /> */}
				<InputGroup size='sm'>
					<Input
						pr='4.5rem'
						type={showToken ? "text" : "password"}
						value={refreshToken}
						onChange={(e) => setRefreshToken(e.target.value)}
					/>
					<InputRightElement width='3.5rem'>
						<Button h='1.75rem' size='xs' onClick={handleClickToken}>
							{showToken ? "Hide" : "Show"}
						</Button>
					</InputRightElement>
				</InputGroup>
			</Td>
			<Td>
				<VStack>
					<Button colorScheme='green' size='xs' variant='outline' onClick={onSaveStore}>
						<MdOutlineDataSaverOn style={{ marginRight: "1px" }} />
						Save Changes
					</Button>
					{/* <Button colorScheme='red' size='xs' variant='outline'>
						<DeleteIcon mr='1px' />
						Remove Store
					</Button> */}
				</VStack>
			</Td>
		</Tr>
	);
};

export default StoreTableItem;
