import { createSlice } from '@reduxjs/toolkit';

export const initialState = {
    loading: false,
    finantialInfoLoading: true,
    error: null,
    userInfo: JSON.parse(localStorage.getItem('userInfo')) ?? null,
    serverMsg: null,
    serverStatus: null,
    showLogin: true,
    showSignup: false,
    showForgotPwd: false,
    stripeIntentSecret: null,
    stripeCustomerSessionClientSecret: null,
    stripeCreditCardInfo: null,
    curNavTab: 'nav-dashboard',
};

export const userSlice = createSlice({
    name: 'user',
    initialState,
    reducers: {
        setLoading: (state) => {
            state.loading = true;
        },
        setFinantialInfoLoading: (state, { payload }) => {
            state.finantialInfoLoading = payload;
        },
        userLogin: (state, { payload }) => {
            state.error = null;
            state.loading = false;
            state.userInfo = payload;
            localStorage.setItem('userInfo', JSON.stringify(payload));
        },
        userLogout: (state) => {
            state.error = null;
            state.loading = false;
            state.userInfo = null;
            localStorage.removeItem("userInfo")
        },
        setError: (state, { payload }) => {
            state.error = payload;
            state.loading = false;
        },
        verificationEmail: (state) => {
            state.userInfo && (state.userInfo.active = true);
            state.loading = false;
            state.error = null
        },
        setServerResponseMsg: (state, { payload }) => {
            state.error = null;
            state.serverMsg = payload;
            state.loading = false;
        },
        setServerResponseStatus: (state, { payload }) => {
            state.error = null;
            state.serverStatus = payload;
            state.loading = false;
        },
        stateReset: (state) => {
            state.loading = false;
            state.serverMsg = null;
            state.error = null;
        },
        setShowLogin: (state, { payload }) => {
            state.error = null;
            state.showLogin = payload;
            state.showSignup = !payload;
            state.showForgotPwd = !payload;
        },
        setShowSignup: (state, { payload }) => {
            state.error = null;
            state.showSignup = payload;
            state.showLogin = !payload;
            state.showForgotPwd = !payload;
        },
        setShowForgotPwd: (state, { payload }) => {
            state.error = null;
            state.showForgotPwd = payload;
            state.showSignup = !payload;
            state.showLogin = !payload;
        },
        setStripeIntentSecret: (state, { payload }) => {
            state.error = null;
            state.stripeIntentSecret = payload
            state.loading = false;
        },
        setStripeCustomerSessionClientSecret: (state, { payload }) => {
            state.error = null;
            state.stripeCustomerSessionClientSecret = payload
            state.loading = false;
        },
        setStripeCreditCardInfo: (state, { payload }) => {
            state.error = null;
            state.stripeCreditCardInfo = payload
            state.loading = false;
        },
        setStripeCustomerId: (state, { payload }) => {
            state.error = null;
            state.userInfo.stripeCustomerId = payload
            localStorage.setItem('userInfo', JSON.stringify(state.userInfo));
            state.loading = false;
        },
        setCurNavTab: (state, { payload }) => {
            state.error = null;
            state.curNavTab = payload
            state.loading = false;
        },
        setStoreInfo: (state, { payload }) => {
            state.error = null;
            state.userInfo.store = payload
            localStorage.setItem('userInfo', JSON.stringify(state.userInfo));
            state.loading = false;
        },
        setCredit: (state, { payload }) => {
            state.error = null;
            state.userInfo.credit = payload
            localStorage.setItem('userInfo', JSON.stringify(state.userInfo));
            state.loading = false;
        },
    },
});

// The followings are actually generated action creators, not actions themselves.
// Action creators and action types are generated for each case reducer function.
export const {
    setUserOrders,
    setError,
    setLoading,
    setServerResponseStatus,
    setServerResponseMsg,
    userLogin,
    userLogout,
    verificationEmail,
    stateReset,
    setShowForgotPwd,
    setShowLogin,
    setShowSignup,
    setStripeIntentSecret,
    setStripeCustomerSessionClientSecret,
    setStripeCreditCardInfo,
    setStripeCustomerId,
    setFinantialInfoLoading,
    setCurNavTab,
    setStoreInfo,
    setCredit
} = userSlice.actions;

export default userSlice.reducer;

export const userSelector = (state) => state.user;